.ebookMainContent {
  position: relative;
  padding: 80px 20px 32px 20px;
}
.ebookSection {
  &.leftRightSection {
    .baseImgWrap {
      width: 300px;
      left: 100px;
      position: relative;
    }
    .baseImg {
      width: 400px;
      margin-bottom: -70px;
      margin-right: -100px;
      margin-top: -100px;
      margin-left: -60px;
    }
    .shape1 {
      position: absolute;
      top: -260px;
      left: -50%;
      transform: scaleX(-1) scaleY(-1);
      opacity: 0.3;
      z-index: -1;
    }

    .floatingItem1 {
      top: 160px;
      left: -69px;
    }

    .floatingItem2 {
      right: -70px;
      bottom: 0px;
    }
  }
}

.ebookTextWrap {
  margin: 50px auto 50px auto;
  .text {
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 1023px) {
  .ebookSection {
    &.leftRightSection {
      flex-direction: column;
      margin: 0 0 100px 0;
      padding: 0;
      .shape1 {
        width: 100%;
        left: 0;
      }

      .text,
      .heading {
        margin: 0 auto;
        text-align: center;
      }

      .heading {
        margin-bottom: 18px;
      }

      .baseImgWrap {
        position: relative;
        margin: 0 auto;
        left: initial;
      }

      .floatingItem3 {
        top: initial;
        bottom: 50px;
      }
    }
  }
}

@media (max-width: 630px) {
  .ebookSection {
    &.leftRightSection {
      .baseImgWrap {
        width: 220px;
      }
      .baseImg {
        width: 100%;
        margin: 0;
      }
    }
  }
  .ebookMainContent {
    padding: 0px 20px 0px 20px;
  }
}
