.h4_btn {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: @euclidBold;
  padding: 20px 20px 20px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
  transition: 256ms all;

  svg {
    color: inherit;
    margin: 0 0 0 12px;
    width: auto;
    max-width: 20px;
    transition: 256ms all;
  }
  span {
    text-align: center;
  }
}

.buttonLight {
  border-radius: 8px;
  min-width: 192px;
  background: rgba(0, 157, 245, 0.12);
  color: #009df5;
  font-size: 1rem;
  letter-spacing: -0.31px;
  svg {
    color: #009df5;
  }
  &:hover {
    background: #009df5;
    color: #fff;
    svg {
      color: #fff;
    }
  }
}

.buttonCTA {
  border-radius: 8px;
  min-width: 235px;
  max-width: 235px;
  background: #83d63f;
  color: #fff;
  font-size: 1rem;
  letter-spacing: -0.31px;
  &:hover {
    background: #6aba28;
    color: #fff;
    svg {
      color: #fff;
    }
  }
}
.buttonCTA.buttonCTAwide {
  min-width: 250px;
  max-width: 250px;
}

.h4_btn18 {
  font-size: 18px;
  svg {
    height: 17px;
    margin-top: 3px;
  }
}
.h4_btn16 {
  font-size: 16px;
  svg {
    height: 14px;
    margin-top: 3px;
  }
}
