.newsletterRegister {
  position: relative;
  padding: 50px 20px 20px 20px;
  
  h1 {
    margin: 0 auto 60px auto;
    font-family: 'Euclid-SemiBold', 'Arial';
    font-size: 2.625rem;
    letter-spacing: -0.82px;
    text-align: center;
    max-width: 800px;
  }
  
  .textAbove {
    margin: 0 auto 60px auto;
    max-width: 800px;
  }
  .textBelow {
    margin: 0 auto 60px auto;
    max-width: 800px;
  }
  .leftRightSection {
    margin-top: 66px;
    margin-bottom: 0px;

    >div {
      width: 45%;
  
      .demoImageWrapper {
        width: 100%;
        margin-bottom: 16px;
  
        img {
          display: block;
          position: relative;
          top: 32px;
          height: auto;
          margin: 0 auto;
          width: 80%;
        }
      }
    }
    .baseImg {
      margin-top: 80px;
    }
    ul.newsletterTopics {
      list-style-type: none;

      li {
        margin-bottom: 12px;
      }
    }
  }
  
  .buttonLight {
    min-width: 300px;
    max-width: 300px;
  }
  
  .informationNotice {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 22px;
    max-width: 100%;
    display: block;
    font-size: 16px;
    margin: 0 0 20px 0;
    cursor: pointer;
  
    &.infoNoticeOpen {
      white-space: initial;
      max-height: initial;
      display: block;
      cursor: default;
    }
    a {
      text-decoration: underline;
    }
  }
  
  .successFormWrapper .text {
    margin: 0px 0px 22px 0px;
    max-width: unset;
  }
  }
  
  .demoBG {
    position: absolute;
    top: -50px;
    left: -40%;
    transform: scaleX(-1) scaleY(-1);
    opacity: 0.8;
    z-index: -1;
  }
  
  .demoShapeWrap {
    position: absolute;
    top: 7%;
    right: 10%;
    z-index: -1;
  
    .demoShape1 {
      position: absolute;
      top: 25px;
      left: -45px;
      opacity: 0.9;
    }
  
    .demoShape2 {
      position: absolute;
      bottom: -35px;
      left: -24px;
      width: 40px;
      opacity: 0.5;
    }
  
    .demoShape3 {
      position: relative;
      width: 60px;
      opacity: 0.3;
    }
  }
  
  .demoShapeWrap2 {
    position: absolute;
    bottom: 15%;
    left: 0;
    transform: rotate(245deg);
    z-index: -1;
  
    .demoShape1 {
      position: absolute;
      top: 25px;
      left: -45px;
      opacity: 0.9;
    }
  
    .demoShape2 {
      position: absolute;
      bottom: -35px;
      left: -24px;
      width: 40px;
      opacity: 0.5;
    }
  
    .demoShape3 {
      position: relative;
      width: 60px;
      opacity: 0.3;
    }
  }
  
  .demoCTA {
    margin-top: 80px;
  
    .footerInit {
      padding: 0px;
  
      .h4_container {
        display: flex;
        justify-content: center;
  
        .leftCTASection,
        .rightCTASection {
          display: flex;
          justify-content: center;
          position: relative;
          height: 100%;
          min-height: 100px;
          width: 100%;
          max-width: 100%;
  
          &>div {
            width: 100%;
            max-width: 380px;
          }
        }
  
        .footerGirl {
          display: block;
          bottom: -180px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0px 0px 0px 0px;
        }
      }
    }
  }
  
  .formCardNoItem {
    padding-top: 20px;
  }
  
  .formCardItem {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0px 18px 7px rgba(57, 81, 94, 0.09);
  }
  
  @media (max-width: 1023px) {
    .leftRightSection>div.aos-init.formCardItem {
      margin: 50px auto 20px auto;
    }
  
    .newsletterRegister {
      padding: 50px 20px 0px 20px;
  
      h1 {
        margin: 0;
      }
      .textAbove {
        margin: 0px 0px 20px 0px;
      }
      .textBelow {
        margin: 20px 0px 0px 0px;
      }
  
      .leftRightSection {
        flex-direction: column;
        margin-top: 13px;
  
        >div {
          width: 100%;
          margin-top: 0px;
        }
        .baseImg {
          margin-top: 0px;
        }
      }
    }
  
    .demoBG {
      width: 100%;
    }
  
    .clients {
      .clientsList {
        .clientsListItem {
          margin: 0px;
        }
      }
    }
  
    .demoCTA {
      .footerInit {
        .h4_container {
          flex-direction: column-reverse;
  
          .leftCTASection,
          .rightCTASection {
            margin: 0px;
  
            &>div {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
  
              &:first-of-type {
                margin-bottom: 32px;
              }
  
              .heading,
              .text {
                text-align: center;
                text-align-last: center;
              }
            }
          }
  
          .footerGirl {
            left: 0;
            transform: translateX(0);
            margin: 0 auto;
          }
        }
      }
    }
  }
  
  @media (max-width: 630px) {
    .newsletterRegister {
      .leftRightSection {
        .textSection {
          .demoImageWrapper {
            margin-bottom: 0px;
  
            img {
              top: 16px;
            }
          }
  
          .squareOuter {
            margin: 36px 0 20px 0;
          }
        }
      }
    }
  
    .demoCTA {
      margin-top: 0px;
    }
  }

  .newsletterShapeWrapper {
    position: absolute;
    top: 200px;
    left: 0;
    transform: rotate(245deg);
    z-index: -1;
  
    .newsletterShape1 {
      position: absolute;
      top: 25px;
      left: -45px;
      opacity: 0.9;
    }
  
    .newsletterShape2 {
      position: absolute;
      bottom: -35px;
      left: -24px;
      width: 40px;
      opacity: 0.5;
    }
  
    .newsletterShape3 {
      position: relative;
      width: 60px;
      opacity: 0.3;
    }
  }