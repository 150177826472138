.cookiePolicy {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 12px 20px;
  width: 100%;
  background: #fff;
  box-shadow: -2px -2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 10000;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.cookieContainer {
  display: flex;
  align-items: center;
  align-items: center;
  max-width: 1184px;
}

.cookiesMessage {
  padding: 0 50px 0 20px;
  font-size: 10px;
  font-family: @euclidRegular;
  flex: 1;
}

.cookiesButtonContainer {
  min-width: 217px;
}

.cookiesButton {
  padding: 9px 5px;
  max-height: 34px;
  max-width: 217px;
  background: #83d63f;
  border-radius: 4px;
  font-size: 10px;
  font-family: @euclidBold;
  line-height: 1.7;
  font-family: @euclidBold;
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 157, 245, 0.12);
  color: #009df5;
}

.cookiesLink {
  color: #009df5;
}

.buttonFullWidth {
  display: block;
  margin: 0;
  padding: 10px 5px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.cookiePolicy.show {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 630px) {
  .cookieContainer {
    flex-direction: column;
    align-items: center;
    .cookiesMessage {
      text-align: center;
      text-align-last: center;
      margin-bottom: 16px;
      padding: 0px 20px;
    }
  }
}
