.footerInit {
  position: relative;
  .h4_container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 180px 20px;
    > div {
      max-width: 380px;
    }
  }
  a {
    margin: 30px 0 0px 0;
    min-width: 235px;
    max-width: 235px;
  }
  .footerGirl {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0px;
    left: 0;
    margin: 0 auto;
    max-height: 480px;
  }
}

.footerInitLeft {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    147.41deg,
    #ffffff 0%,
    rgba(206, 237, 255, 0.74) 100%
  );
  z-index: -1;
}

footer {
  background: #fff;
  .h4_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 110px 20px 110px 20px;
  }
  .footerLogo {
    padding-right: 20px;
    width: 100%;
    max-width: 310px;
    .logo {
      top: -8px;
    }
    .footerLogoText {
      font-family: @euclidRegular;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.27px;
    }
  }
}

.footerTextContainer {
  position: relative;
  padding-top: 257px;
  overflow: hidden;

  .text {
    max-width: 100%;
  }

  .bgImage {
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }

  .shapeHexBig {
    top: 1%;
    left: 45%;
    height: 1348px;
    width: auto;
  }

  .shapeHex1 {
    top: 6%;
    left: 51%;
    height: 123px;
    width: auto;
    animation-duration: 8s;
  }

  .shapeHex2 {
    bottom: 0%;
    left: 33%;
    height: 115px;
    width: auto;
    animation-duration: 9s;
  }

  .shapeHexSmall {
    top: 14%;
    left: 46%;
    height: 23px;
    width: auto;
    animation-duration: 5s;
  }

  .leftRightContainer {
    display: flex;
    width: 100%;
  }

  .leftTextContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 466px;
  }

  .rightTextContainer {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    padding-left: 16px;
    width: 100%;
  }

  .rightText {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 500px;
    max-width: 561px;
  }

  .footerTextImage {
    margin: 24px 0px 0px;
    .footerSchema {
      position: relative;
      left: -18px;
    }
  }

  @media (max-width: 1023px) {
    padding-top: 80px;
    .heading {
      margin-top: 32px;
    }
    .text {
      padding-bottom: 16px;
    }

    .shapeHexBig {
      top: 10%;
    }

    .shapeHex2 {
      left: 76%;
    }

    .shapeHex1 {
      top: 20px;
      left: 51%;
      height: 123px;
      width: auto;
    }
    .shapeHexSmall {
      top: 65px;
      left: 46%;
      height: 23px;
      width: auto;
    }

    .leftRightContainer {
      flex-direction: column;
    }

    .leftTextContainer,
    .rightText {
      width: 100%;
      min-width: 0px;
      max-width: 100%;
    }

    .rightTextContainer {
      justify-content: flex-start;
      padding-left: 0px;
    }

    .footerTextImage {
      display: flex;
      justify-content: center;
      margin: 24px 0px 0px;
      width: 100%;
      .footerSchema {
        position: inherit;
        left: 0px;
        width: 100%;
        max-width: 450px;
      }
    }
  }
}

.footerNav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 672px;
}

.footerItem {
  display: flex;
  flex-direction: column;
  .footerItemHeader,
  .footerHeader {
    margin: 0 0 20px 0;
    font-family: @euclidBold;
    font-size: 1.125rem;
    line-height: 23px;
    letter-spacing: -0.35px;
  }
  > .footerLink {
    display: flex;
    align-items: center;
  }
  > .footerLink > p,
  > .footerLink > a {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-decoration: none;
    letter-spacing: -0.27px;
  }
  .footerP {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.27px;
  }
  > .footerLink > a {
    position: relative;
    width: auto;
    &:before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      height: 8%;
      width: 100%;
      background: #009df5;
      transition: transform 250ms ease-in-out;
      content: '';
    }
    &:hover {
      &:before {
        transform: scaleX(1);
        transform-origin: 0 50%;
      }
    }
  }
  .footerDemo {
    color: #009df5;
  }
}

.bottomFooter {
  padding: 20px;
  width: 100%;
  a,
  p {
    font-size: 12px;
    text-align: center;
  }
  a {
    display: block;
    position: relative;
    margin: 0 auto;
    padding: 10px 0;
    width: 120px;

    &:before {
      display: block;
      position: absolute;
      bottom: 8px;
      left: 0;
      transform: scaleX(0);
      height: 2px;
      width: 100%;
      background: #009df5;
      transition: transform 250ms ease-in-out;
      content: '';
    }
    &:hover {
      &:before {
        transform: scaleX(1);
        transform-origin: 0 50%;
      }
    }
  }
}

@media (max-width: 1023px) {
  .footerInit {
    overflow: hidden;
  }
  .footerInit {
    padding: 60px 20px 0 20px;
    .h4_container {
      flex-wrap: wrap;
      padding: 40px 20px 0px 20px;
    }
    .footerGirl {
      order: 3;
      position: static;
      margin: 40px auto 0 auto;
      max-height: 400px;
    }
  }
}

.footerSocial {
  display: flex;
  justify-content: space-between;
  margin: 34px 0 0 0;
  max-width: 150px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    img {
      width: 16px;
    }
    &.footerFacebook {
      background: rgb(10, 127, 249);
    }
    &.footerLinkedin {
      background: rgb(2, 157, 245);
    }
    &.footerInstagram img {
      height: 36px;
      width: 36px;
    }
  }
}

@media (max-width: 950px) {
  footer {
    background: #fff;
    .h4_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 110px 20px 110px 20px;
    }
    .footerLogo {
      width: 100%;
      max-width: 250px;
      .logo {
        margin: 0 40px 0 0;
      }
      .footerLogoText {
      }
    }
  }
}
@media (max-width: 855px) {
  .footerInit {
    padding: 0px 20px 0 20px;
    .h4_container {
      align-items: center;
      flex-direction: column;
      padding: 60px 20px 0 20px;
      > div {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
        &:first-child {
          margin: 0 0 50px 0;
        }
      }
    }
    .footerGirl {
      order: 3;
      position: static;
      margin: 40px auto 0 auto;
      max-height: 400px;
    }
    a {
      margin: 30px auto 0 auto;
    }
    .text {
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    .h4_container {
      flex-direction: column;
    }
    .logo {
      margin: 0 0 30px 0;
    }
  }

  footer {
    .h4_container {
      flex-direction: column;
    }
    .footerLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 24px;
      width: 100%;
      max-width: 100%;
      .logo {
        margin: 0 0 16px 0;
      }
      .footerLogoText {
        text-align: center;
        text-align-last: center;
      }
    }
  }
}
@media (max-width: 630px) {
  footer {
    .h4_container {
      padding: 50px 20px;
    }
  }
  .footerItem {
    h5 {
      margin: 0 0 10px 0;
    }
    > a {
      padding: 5px 0;
    }
  }
  .footerInit {
    .footerGirl {
      right: 0;
      left: 0;
      height: 200px;
    }
  }
}

@media (max-width: 550px) {
  .footerNav {
    flex-wrap: wrap;
  }
  .footerItem {
    margin: 0 0 40px 0;
    width: 50%;
  }
}
@media (max-width: 475px) {
  .footerNav {
    flex-wrap: wrap;
  }
  .footerItem {
    width: 100%;
  }
  address.footerItem {
    margin: 0;
  }
}
