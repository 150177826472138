.heroFunctions {
  position: relative;
  margin: 0 0 -50px 0;
  padding: 61px 0 0px 0;
  .h4_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    min-height: 490px;
    z-index: 2;
  }

  .texth4_container {
    width: 50%;
    h1 {
      margin: 0 0 31px 0;
      font-family: @euclidBold;
      font-size: 2.625rem;
      letter-spacing: -0.82px;
    }
    p {
      margin: 0 0 50px 0;
      max-width: 480px;
      color: #0f365c;
      opacity: 0.8;
      font-size: 1.375rem;
      letter-spacing: -0.43px;
    }
  }

  .clients {
    display: flex;
    align-items: center;
    justify-content: initial;
    margin: 10px auto 0 auto;
    padding: 20px;
    border-top: 1px solid rgba(36, 147, 255, 0.15);
    border-bottom: 1px solid rgba(36, 147, 255, 0.15);
    z-index: 1;
    p {
      margin: 0px 32px 0 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: -0.35px;
    }
    img {
      margin: 0 50px 0 0;
      max-width: 190px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.FunctionsImageContainer {
  position: relative;
  min-height: 490px;
  width: 50%;
  .laptop {
    position: relative;
    top: -9px;
    left: -91px;
    max-width: 601px;
    .laptopImg {
      width: 100%;
    }
  }
  .girlWrap {
    display: flex;
    justify-content: center;
  }
  .girl {
    position: relative;
    top: -50px;
    max-height: 490px;
  }
}

.heroFunctions {
  .herobg {
    position: absolute;
    right: -70%;
    bottom: 120px;
    z-index: -1;
  }
  .shapeBase {
    position: absolute;
  }
  .shape1 {
    top: 130px;
    left: -30px;
    width: 50px;
    opacity: 0.2;
  }
  .shape2 {
    top: 12px;
    right: -27px;
    left: inherit;
    width: 65px;
    opacity: 0.2;
  }
  .shape3 {
    top: 77px;
    right: 168px;
    left: initial;
    width: 28px;
    opacity: 0.2;
  }
  .shape4 {
    bottom: 231px;
    left: 122px;
    width: 30px;
    z-index: 3;
  }
  .iconBase {
    position: absolute;
  }

  .shapeOutline {
    position: absolute;
    top: -3px;
    right: -50%;
    width: 370px;
    z-index: -1;
  }
  .icon1 {
    top: -60px;
    left: -75px;
    width: 150px;
  }
  .icon2 {
    top: 46px;
    left: 53px;
    width: 135px;
  }
  .icon3 {
    top: 247px;
    left: -6px;
    width: 150px;
  }
  .icon4 {
    top: -58px;
    right: 58px;
    width: 175px;
  }
  .icon5 {
    right: 0;
    bottom: 152px;
    width: 210px;
    z-index: 1;
  }
}

.h4_pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 20px 80px 20px;

  .mainHeader {
    text-align: center;
    text-align-last: center;
  }
  .textBelow {
    text-align: center;
    text-align-last: center;
  }
}

.functionNav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #c9dae3;
  border-radius: 10px;
  box-shadow: 0 15px 18px 0 rgba(243, 245, 245, 0.74);
  z-index: 10;
  > a {
    color: #0f365c;
    transition: none;
  }

  > div,
  > a {
    display: block;
    position: relative;
    padding: 22px;
    width: calc(100% / 6);
    border-right: 1px solid #c9dae3;
    font-family: @euclid;
    font-size: 1.125rem;
    letter-spacing: -0.35px;
    cursor: pointer;
    text-align: center;
    &:last-of-type {
      border-right: none;
    }
    &:before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      height: 8%;
      width: 100%;
      background: #009df5;
      transition: transform 250ms ease-in-out;
      content: '';
    }
  }
}

.footerNavContainer {
  display: none;
  margin: 80px 0px;

  .footerFunctionNav {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 190px;
    background-color: #ffffff;
    border: 1px solid #c9dae3;
    border-radius: 10px;
    box-shadow: 0 15px 18px 0 rgba(243, 245, 245, 0.74);
    > a {
      color: #0f365c;
      transition: none;
    }

    > div,
    > a {
      display: block;
      position: relative;
      margin-left: 16px;
      padding: 22px;
      width: 100%;
      border-right: 1px solid #c9dae3;
      font-size: 1rem;
      letter-spacing: -0.35px;
      cursor: pointer;
      text-align: center;

      &:last-of-type {
        border-right: none;
      }
      &:before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        height: 8%;
        width: 100%;
        background: #009df5;
        transition: transform 250ms ease-in-out;
        content: '';
      }
    }

    .arrowLeft,
    .arrowRight {
      svg {
        position: absolute;
        top: 50%;
        height: 14px;
        width: auto;
      }
    }

    .arrowLeft {
      margin-left: 0px;
      padding-left: 38px;

      svg {
        left: 24px;
        transform: translateY(-50%) rotate(-180deg);
      }
    }

    .arrowRight {
      margin-left: 0px;
      padding-left: 22px;
      padding-right: 38px;

      svg {
        right: 24px;
        transform: translateY(-50%);
      }
    }
  }

  .footerFunctionNav.doubleBtn {
    max-width: 380px;
    > div,
    > a {
      width: calc(100% / 2);
    }
  }
}

.h4_pageTitleWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .mainHeader,
  .textBelow {
    text-align: center;
  }
}

.h4_sticky .functionNav .activeNav:before,
.functionPage.functionNav .activeNav:before,
.functionNav > div:hover:before,
.functionNav > a:hover:before,
.footerFunctionNav > a:hover:before {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

@media (min-width: 1200px) {
  // br {
  //   display: none;
  // }
  .floatingText br {
    display: block;
  }
}
@media (min-width: 1023px) {
  #functionNav.h4_sticky {
    position: fixed;
    top: 100px;
    right: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid rgba(201, 218, 227, 0.58);
    box-shadow: 0 15px 18px 0 rgba(57, 81, 94, 0.09);
    z-index: 10;
    .functionNav {
      border: none;
      > div,
      > a {
        padding: 21px 12px;
        border: none;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .heroFunctions {
    .h4_container {
      min-height: 772px;
    }
  }
  .h4_pageTitle {
    padding: 32px 20px 32px 20px;
  }
  .footerNavContainer {
    margin: 0 0 80px 0;
  }
  .functionh4_container {
    margin-top: 32px;
    padding-top: 0px !important;
    overflow: hidden;
  }

  .heroFunctions {
    overflow: hidden;
    .h4_container {
      flex-direction: column;
    }
    .texth4_container {
      position: relative;
      width: 100%;
      z-index: 3;
    }
    .FunctionsImageContainer {
      width: 100%;
    }
  }
  .functionNav {
    flex-wrap: wrap;
    > div,
    > a {
      width: calc(100% / 3);
      &:nth-child(3),
      &:nth-child(6) {
        border-right: none;
      }
      &:nth-child(-n + 3) {
        border-bottom: 1px solid #c9dae3;
      }
    }
  }
  .footerNavContainer {
    display: block;
  }
}

@media (max-width: 700px) {
  .heroFunctions {
    .FunctionsImageContainer {
      padding: 50px 0 0 0;
      min-height: 540px;
    }
  }
}

@media (max-width: 630px) {
  .heroFunctions {
    margin: 0;
    padding: 30px 0 0 0;
    .h4_container {
      min-height: 565px;
    }
    .shape4,
    .icon1,
    .icon5 {
      display: none;
    }
    .icon2 {
      top: 0;
      left: 10px;
    }
    .icon3 {
      left: 0;
    }
    .icon4 {
      right: 0;
    }
    .texth4_container {
      h1 {
        margin: 0 0 20px 0;
        text-align: center;
      }
      p {
        margin: 0 0 30px 0;
        text-align: center;
      }
      a {
        margin: 0 auto;
      }
    }
    .FunctionsImageContainer {
      padding: 80px 0 0 0;
      min-height: 330px;
      .girl {
        margin: 50px 0 0 0;
        margin: 0px 0 -50px 0;
        max-height: 300px;
      }
    }
  }
  .functionNav {
    flex-wrap: wrap;
    > div,
    > a {
      width: calc(100% / 2);
      &:nth-child(3) {
        border-right: 1px solid #c9dae3;
      }
      &:nth-child(2) {
        border-right: none;
      }
      &:nth-child(4) {
        border-right: none;
      }
      &:nth-child(-n + 4) {
        border-bottom: 1px solid #c9dae3;
      }
    }
  }

  .functionNav > div:hover:before,
  .functionNav > a:hover:before,
  .footerFunctionNav > a:hover:before {
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
}

@media (max-width: 414px) {
  .footerNavContainer {
    .footerFunctionNav {
      .arrowLeft {
        padding-right: 16px;
      }

      .arrowRight {
        padding-left: 16px;
      }
    }
  }
}

@media (max-width: 360px) {
  .functionNav,
  .functionNav {
    > div,
    > a {
      font-size: 1rem;
    }
  }

  .footerNavContainer {
    .footerFunctionNav {
      .arrowLeft {
        padding-left: 12px;
        padding-right: 24px;

        font-size: 12px;

        svg {
          left: 16px;
        }
      }

      .arrowRight {
        padding-left: 12px;
        padding-right: 24px;

        font-size: 12px;

        svg {
          right: 16px;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .functionNav,
  .functionNav {
    > div,
    > a {
      font-size: 1rem;
    }
  }

  .footerNavContainer {
    .footerFunctionNav {
      .arrowLeft {
        svg {
          left: 12px;
        }
      }

      .arrowRight {
        svg {
          right: 12px;
        }
      }
    }
  }
}
